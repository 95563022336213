<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <div class="d-flex justify-content-between align-items-center">
                <h4 class="m-0">Contact View</h4>
                <b-button variant="red" squared @click="openDeleteModal" size="sm">Delete</b-button>
              </div>
            </b-col>
          </b-row>

          <b-row class="h-100" v-if="state === 'loading'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
          </b-row>

          <div v-if="state === 'show'">
            <b-row>
              <b-col cols="4">
                <label>Company <span class="text-red">*</span></label>
                <!--                <b-input-group>-->
                <b-form-input
                  disabled
                  v-model="$v.selectedContact.company.$model"
                  @blur="$v.selectedContact.$touch()"
                ></b-form-input>
                <!--                  <b-input-group-append>-->
                <!--                    <b-button size="sm" text="Button" variant="secondary" @click="openSearchModal">Search</b-button>-->
                <!--                  </b-input-group-append>-->
                <!--                </b-input-group>-->
                <!--                <div v-if="$v.selectedContact.company.$error" class="text-red font-weight-400 text-left">-->
                <!--                  <p v-if="!$v.selectedContact.company.required">This is a required field</p>-->
                <!--                </div>-->
              </b-col>
              <b-col cols="4">
                <label>Name <span class="text-red">*</span></label>
                <b-form-input
                  v-model="$v.selectedContact.name.$model"
                  @blur="$v.selectedContact.name.$touch()"
                ></b-form-input>
                <div v-if="$v.selectedContact.name.$error" class="text-red font-weight-400 text-left">
                  <p v-if="!$v.selectedContact.name.required">This is a required field</p>
                </div>
              </b-col>
              <b-col cols="4">
                <label>Surname <span class="text-red">*</span></label>
                <b-form-input
                  v-model="$v.selectedContact.surname.$model"
                  @blur="$v.selectedContact.surname.$touch()"
                ></b-form-input>
                <div v-if="$v.selectedContact.surname.$error" class="text-red font-weight-400 text-left">
                  <p v-if="!$v.selectedContact.surname.required">This is a required field</p>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <label>Email <span class="text-red">*</span> </label>
                <b-form-input
                  v-model="$v.selectedContact.email.$model"
                  @blur="$v.selectedContact.email.$touch()"
                ></b-form-input>
                <div v-if="$v.selectedContact.email.$error" class="text-red font-weight-400 text-left">
                  <p v-if="!$v.selectedContact.email.required">This is a required field</p>
                  <p v-if="!$v.selectedContact.email.email">Please enter a valid email</p>
                </div>
              </b-col>
              <b-col cols="4">
                <label>Landline Number <span class="text-red">*</span></label>
                <b-form-input
                  v-model="$v.selectedContact.phone.$model"
                  @focus="$v.selectedContact.phone.$touch()"
                ></b-form-input>
                <div v-if="$v.selectedContact.phone.$error" class="text-red font-weight-400 text-left">
                  <p v-if="!$v.selectedContact.phone.required">This is a required field</p>
                  <p
                    v-if="
                      !$v.selectedContact.phone.minLength ||
                      !$v.selectedContact.phone.maxLength ||
                      !$v.selectedContact.phone.numeric
                    "
                  >
                    Please enter a valid number
                  </p>
                </div>
              </b-col>
              <b-col cols="4">
                <label>Mobile Number <span class="text-red">*</span></label>
                <b-form-input
                  v-model="$v.selectedContact.cellNumber.$model"
                  @blur="$v.selectedContact.cellNumber.$touch()"
                ></b-form-input>
                <div v-if="$v.selectedContact.cellNumber.$error" class="text-red font-weight-400 text-left">
                  <p v-if="!$v.selectedContact.cellNumber.required">This is a required field</p>
                  <p
                    v-if="
                      !$v.selectedContact.cellNumber.minLength ||
                      !$v.selectedContact.cellNumber.maxLength ||
                      !$v.selectedContact.cellNumber.numeric
                    "
                  >
                    Please enter a valid number
                  </p>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <label>Location <span class="text-red">*</span></label>
                <b-form-select v-model="selectedContact.locationID">
                  <b-form-select-option v-for="(item, index) in locations" :key="index" :value="item.locationId">{{
                    item.locationName
                  }}</b-form-select-option>
                </b-form-select>
              </b-col>
              <b-col cols="4" v-if="selectedContact.locationID === 0">
                <label>Location Name<span class="text-red">*</span></label>
                <b-form-input v-model="locationValues.locationName"></b-form-input>
                <p v-if="locationFilled === false">This is a required field</p>
              </b-col>
              <b-col cols="4" v-if="selectedContact.locationID === 0">
                <label>Address<span class="text-red">*</span></label>
                <b-form-input v-model="locationValues.address"></b-form-input>
                <p v-if="locationFilled === false">This is a required field</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4" v-if="selectedContact.locationID === 0">
                <label>City</label>
                <b-form-input v-model="locationValues.city"></b-form-input>
              </b-col>
              <b-col cols="4" v-if="selectedContact.locationID === 0">
                <label>Country</label>
                <b-form-input v-model="locationValues.country"></b-form-input>
              </b-col>
              <b-col cols="4" v-if="selectedContact.locationID === 0">
                <label>Postal Code</label>
                <b-form-input v-model="locationValues.postalCode"></b-form-input>
              </b-col>
            </b-row>
            <hr class="mx-3" />
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button variant="red" squared @click="goBackToSearch" class="ml-2">Cancel</b-button>
                  </div>
                  <div>
                    <b-button
                      variant="primary"
                      squared
                      @click="locationCreate"
                      class="ml-2"
                      :disabled="$v.selectedContact.$invalid"
                      >Save</b-button
                    >
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="deleteModal" hide-footer hide-header-close title="Delete Contact">
      <b-row>
        <b-col cols="12">
          <label class="text-center"
            >Are you sure you want to delete
            <span class="font-weight-bold text-red text-center"
              >{{ this.selectedContact.name }} {{ this.selectedContact.surname }}</span
            >?</label
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-center mt-3">
          <b-button variant="red" @click="hideDeleteModal" squared class="mr-2">Cancel</b-button>
          <b-button variant="red" @click="removeContact" squared>Delete</b-button>
        </b-col>
      </b-row>
      <div class="d-block"></div>
    </b-modal>
    <b-modal id="searchModal" hide-footer hide-header-close title="Company Search" size="xl">
      <b-row>
        <b-col cols="4">
          <label>Company Name</label>
          <b-form-input v-model="selectedContact.company.description"></b-form-input>
        </b-col>
      </b-row>
      <hr class="mx-3" />
      <b-row>
        <b-col cols="12" class="text-right">
          <b-button variant="red" squared class="mr-2">Clear</b-button>
          <b-button variant="primary" squared>Search</b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-table
          striped
          hover
          :items="tableCompanyData.dataSource"
          :fields="tableCompanyData.tableColumns"
          :busy="tableCompanyData.isLoading"
          :per-page="tableCompanyData.resultsPerPage"
          id="companySearch"
          :current-page="tableCompanyData.currentPage"
          sort-icon-left>
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
            </div>
          </template>

          <template>
            <b-row align-v="center" align-h="end">
              <b-button size="sm" class="btn-icon">
                <b-icon-chevron-right></b-icon-chevron-right>
              </b-button>
            </b-row>
          </template>
        </b-table>
      </b-row>
      <b-row align-h="center">
        <b-pagination
          v-model="tableCompanyData.currentPage"
          :total-rows="rows"
          :per-page="tableCompanyData.resultsPerPage"
          aria-controls="companySearch"
        ></b-pagination>
      </b-row>
      <b-row class="mt-4">
        <b-col class="text-right">
          <b-button variant="red" class="mr-2" squared @click="hideSearchModal">Cancel</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { required, email, minLength, maxLength, numeric } from 'vuelidate/lib/validators';

export default {
  name: 'contactView',
  data: () => ({
    state: 'loading',
    contact: {
      name: null,
      surname: null,
      email: null,
      cellNumber: null,
      companyId: null,
      company: null,
      active: null,
      landlineNumber: null,
      location: { locationId: null },
      newLocation: { locationName: null, address: null, city: null, country: null, postalCode: null },
    },
    tableCompanyData: {
      resultsPerPage: 4,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Company',
          key: 'description',
          sortable: true,
          tdClass: '',
        },
        {
          label: '',
          key: 'actions',
          sortable: true,
          tdClass: '',
        },
      ],
    },
    locationValues: {
      companyID: null,
      locationName: null,
      address: null,
      city: null,
      country: null,
      postalCode: null,
    },
    selected: 0,
    locations: [],
    locationFilled: null,
  }),
  created() {
    this.getSelectedContact();
    this.setBreadcrumb([
      {
        text: 'Contact',
      },
      {
        text: 'Details',
      },
    ]);
    this.state = 'loading';
    this.selected = this.selectedContact.location;
  },
  methods: {
    ...mapActions([
      'getContactDetail',
      'updateContact',
      'deleteContact',
      'searchCompanies',
      'searchLocationByCompany',
      'registerNewLocation',
    ]),
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),

    getSelectedContact() {
      if (this.$store.state.selectedContact == null) {
        this.$router.push({ path: '/contacts/contactSearch' });
      }
      this.getContactDetail().then((response) => {
        this.$store.commit('setSelectedContact', response.data);
        this.state = 'show';
        this.locationSearchOnLoad(response.data.companyID);
      });
    },

    updateContactDetail() {
      this.selectedContact.location = this.selected.locationName;
      this.$store.commit('selectedContact', this.selectedContact);
      this.state = 'loading';
      this.updateContact()
        .then(() => {
          this.goBackToSearch();
        })
        .catch(() => {
          this.state = 'show';
        });
    },

    goBackToSearch() {
      this.$router.push({ path: '/contacts/contactSearch' });
    },

    removeContact() {
      this.state = 'loading';
      this.selectedContact.active = false;
      this.$store.commit('selectedContact', this.contact);
      this.deleteContact().then(() => {
        this.$router.push({ path: '/contacts/contactSearch' });
      });
    },

    openDeleteModal() {
      this.$bvModal.show('deleteModal');
    },

    hideDeleteModal() {
      this.$bvModal.hide('deleteModal');
    },

    hideSearchModal() {
      this.$bvModal.hide('searchModal');
    },

    locationSearchOnLoad(data) {
      let request = {
        companyID: data,
      };
      this.$store.commit('setSearchLocationByCompanyRequest', request);

      this.searchLocationByCompany().then((response) => {
        this.locations = response.data;
        this.locations.unshift({ locationId: 0, locationName: 'Add new location' });
      });
    },

    locationCreate() {
      if (this.selectedContact.locationID === 0) {
        this.locationValues.companyID = this.selectedContact.companyID;
        // this.selectedContact.newLocation.locationName = this.locationValues.locationName
        // this.selectedContact.newLocation.address = this.locationValues.address
        this.$store.commit('setLocationCreateRequest', this.locationValues);
        this.registerNewLocation()
          .then((res) => {
            this.selectedContact.location = {
              locationId: res.data.result.locationId,
              locationName: this.selectedContact.newLocation.locationName,
              companyID: this.selectedContact.companyId,
            };
            this.updateContactDetail();
            this.locationSearchOnLoad(this.selectedContact.companyID);
          })
          .catch((e) => {
            console.log('Error', e.message);
          });
      } else {
        this.updateContactDetail();
      }
    },
  },
  computed: {
    ...mapState(['selectedContact']),
    rows() {
      return this.tableCompanyData.dataSource.length;
    },
  },
  validations: {
    selectedContact: {
      company: { required },
      name: { required },
      surname: { required },
      // location: {required},
      phone: { required, minLength: minLength(10), maxLength: maxLength(10), numeric },
      cellNumber: { required, minLength: minLength(10), maxLength: maxLength(10), numeric },
      email: { required, email },
    },
    // request: {
    //   company :{
    //     companyName: {required}
    //   }
    // },
  },
};
</script>

<style scoped></style>
